import { FC } from 'react'

import { strings } from '../../models/consts/strings'
import { Button } from '../Button/Button'
import styles from './EmptyDataMsg.module.scss'
import { PlusIcon } from '../../assets/icons'

interface EmptyDataMsgProps {
  onNewProjectClick: () => void
  msg: string
}

const EmptyDataMsg: FC<EmptyDataMsgProps> = ({ onNewProjectClick, msg }) => {
  return (
    <div className={styles.emptyData}>
      <p className={styles.msg}>{strings.EMPTY_CASES.EMPTY_PROJECTS}</p>
      <Button Icon={PlusIcon} title={strings.DATA_LIST.NEW_BUTTON} onClick={onNewProjectClick} />
    </div>
  )
}

export { EmptyDataMsg }

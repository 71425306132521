import { apiEndpoints } from '../constants/api-endpoints'
import { INews } from '../models/interfaces/news.interface'
import { apiRequest } from '../utils/api-utils/api'

const fetchUserProjects = async (userId: string, page: number = 1, limit: number = 2) => {
  const response = await apiRequest('GET', apiEndpoints.PROJECTS.GET_USER_PROJECTS(userId, page, limit))
  const projects = response.data.projects.map((project: any) => ({
    id: project.id,
    clientName: project.businessUnit?.client?.name || 'N/A',
    name: project.name,
    status: project.status,
    lastUpdated: project.updatedAt,
  }))
  return {
    projects,
    meta: response.data.meta,
  }
}

const fetchCompaniesNews = async (newsCompanies: string) => {
  const response = await apiRequest('GET', apiEndpoints.NEWS.GET_COMPANIES_NEWS(newsCompanies))
  const news = response.data.map((news: INews) => ({
    name: news?.relatedKeywords[0],
    title: news.title,
    description: news.description,
    content: news.content,
    publishedAt: news.publishedAt,
    source: news.source,
    url: news.url,
  }))
  return news
}

const createProjectApi = async (name: string, businessUnitId: string, userIds: string[]) => {
  const response = await apiRequest('POST', apiEndpoints.PROJECTS.CREATE_PROJECT, {
    name,
    businessUnitId,
    userIds,
  })
  return response
}

const fetchClients = async () => {
  return await apiRequest('GET', apiEndpoints.CLIENTS.GET_ALL_CLIENTS)
}

const fetchBusinessUnits = async (clientId: string) => {
  return await apiRequest('GET', apiEndpoints.BUSINESS_UNITS.GET_BUSINESS_UNIT(clientId))
}

const fetchAllUsers = async () => {
  return await apiRequest('GET', apiEndpoints.USERS.GET_ALL_USERS)
}

export { createProjectApi, fetchAllUsers, fetchBusinessUnits, fetchClients, fetchCompaniesNews, fetchUserProjects }

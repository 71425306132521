import { Modal } from 'antd'
import { FC, ReactNode } from 'react'

import './CustomModal.scss'

interface CustomModalProps {
  open: boolean
  onCancel: () => void
  footer?: ReactNode
  className?: string
  children: ReactNode
}

const CustomModal: FC<CustomModalProps> = ({ open, onCancel, footer = null, className = '', children }) => {
  return (
    <Modal open={open} onCancel={onCancel} footer={footer} className={`custom-modal ${className}`}>
      {children}
    </Modal>
  )
}

export { CustomModal }

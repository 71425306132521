import axios from 'axios'
import { loginRequest } from '../../config/auth-config'
import { acquireToken } from '../auth-utils/acquire-token-silent'
import { ensureMsalInitialized, msalInstance } from '../auth-utils/msalInstance'
import { ErrorMessages } from '../../models/enums/error-messages.enum'

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVER_URL,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    await ensureMsalInitialized()
    const token = await acquireToken(msalInstance, loginRequest.scopes)
    if (token) {
      config.headers = config.headers || {}
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    console.error('Request Error:', error.message)
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      console.error('Network Error:', error.message)
      return Promise.reject(new Error(ErrorMessages.NETWORK_ERROR))
    }

    const { status, data } = error.response

    switch (status) {
      case 400:
        console.error('Bad Request:', data?.message || error.message)
        return Promise.reject(new Error(data?.message || ErrorMessages.BAD_REQUEST))
      case 401:
        console.error('Unauthorized:', data?.message || error.message)
        return Promise.reject(new Error(data?.message || ErrorMessages.UNAUTHORIZED))
      case 403:
        console.error('Forbidden:', data?.message || error.message)
        return Promise.reject(new Error(data?.message || ErrorMessages.FORBIDDEN))
      case 404:
        console.error('Not Found:', data?.message || error.message)
        return Promise.reject(new Error(data?.message || ErrorMessages.NOT_FOUND))
      case 500:
        console.error('Server Error:', data?.message || error.message)
        return Promise.reject(new Error(ErrorMessages.SERVER_ERROR))
      default:
        console.error(`Error ${status}:`, data?.message || error.message)
        return Promise.reject(new Error(data?.message || `${ErrorMessages.UNEXPECTED_ERROR} ${status}`))
    }
  },
)

export default axiosInstance

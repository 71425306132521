import { Config } from '../constants'

const msalConfig = {
  auth: {
    clientId: Config.entraClientId || '',
    authority: `${Config.entraAuthorityUrl}/${Config.entraTenantId}`,
    redirectUri: Config.baseUrl,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

const loginRequest = {
  scopes: ['f381eba8-7cfc-4ca9-a5a2-0e495fcac316/.default'],
}

export { msalConfig, loginRequest }

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserState {
  email: string
  userId: string
}

/* TODO: change userId to the id from the login */

const initialState: UserState = {
  email: '',
  userId: 'bd275544-9aea-4fc5-8e39-e8a932339753',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ email: string; userId: string }>) => {
      state.email = action.payload.email
      state.userId = action.payload.userId
    },
    logout: (state) => {
      state.email = ''
      state.userId = ''
    },
  },
})

export const { login, logout } = userSlice.actions
export default userSlice.reducer

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IProject } from '../../models/interfaces/project.interface'
import { fetchUserProjects, createProjectApi } from '../../services/command-center-service'
import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'

interface ProjectsState {
  projects: IProject[]
  isLoading: boolean
  error: string | null
  page: number
  limit: number
  totalItems: number
  totalPages: number
}

const initialState: ProjectsState = {
  projects: [],
  isLoading: false,
  error: null,
  page: PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: PAGINATION_CONFIG.DEFAULT_LIMIT,
  totalItems: 0,
  totalPages: 0,
}

export const fetchProjects = createAsyncThunk<
  { projects: IProject[]; meta: { totalItems: number; totalPages: number } },
  { userId: string; page?: number; limit?: number }
>(
  'projects/fetchProjects',
  async ({ userId, page = PAGINATION_CONFIG.DEFAULT_PAGE, limit = PAGINATION_CONFIG.DEFAULT_LIMIT }) => {
    const projects = await fetchUserProjects(userId, page, limit)
    return projects
  },
)

export const createProject = createAsyncThunk<void, { name: string; businessUnitId: string; userIds: string[] }>(
  'projects/createProject',
  async ({ name, businessUnitId, userIds }) => {
    const response = await createProjectApi(name, businessUnitId, userIds)
    return response.data
  },
)

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    clearProjects: (state) => {
      state.projects = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchProjects.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.projects = action.payload.projects
        state.totalItems = action.payload.meta.totalItems
        state.totalPages = action.payload.meta.totalPages
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'Failed to fetch projects'
      })
      .addCase(createProject.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(createProject.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'Failed to create project'
      })
  },
})

export const { clearProjects } = projectsSlice.actions

export default projectsSlice.reducer

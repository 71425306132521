export const strings = {
  EMPTY_CASES: {
    OFFLINE: 'It seems you’re offline. \n Please check your internet connection and try again.',
    EMPTY_PROJECTS: 'No current projects? \n Let’s change that - start your messaging project now!',
    ERROR_MSG: 'Failed to fetch projects. Please try again.',
  },
  DATA_LIST: {
    NEW_BUTTON: 'New project',
  },
  COMMAND_CENTER_TITLES: {
    DATA_LIST: 'My current projects',
    NEWS_SCROLLER: 'My clients news',
  },
  PROJECT_MODAL: {
    TITLE: 'New messaging project',
    LABELS: {
      PROJECT_NAME: 'Project name',
      CLIENT_NAME: 'Client name',
      BUSINESS_UNIT: 'Business unit',
      PROJECTS_MEMBERS: 'Project members',
    },
    ERRORS: {
      ERROR_MSG: 'Failed to create \n messaging project',
      ERROR_MSG2: 'Please try again',
      ERROR_BACK_BUTTON: 'BACK',
      PROJECT_ERROR: 'Error creating project:',
    },
    FORM_COMPLETE_BUTTON: 'Create',
  },
  GENERAL_LABELS: {
    ACTION_BUTTON: 'Open details for ',
    SAVING: 'Saving...',
  },
}

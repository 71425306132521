import React, { FC, ReactNode } from 'react'
import { EListViewColumns, columnKeyMap } from '../../../models/enums/list-columns.enum'
import { IProject } from '../../../models/interfaces/project.interface'
import { formatRelativeDate } from '../../../utils/format-date.util'
import styles from '../ListView.module.scss'
import { formatStatus, getStatusClass } from '../list-view.utils'

interface ITableRowProps {
  row: IProject
  columns: EListViewColumns[]
}

const renderCellContent = (column: EListViewColumns, cell: any): ReactNode => {
  switch (column) {
    case EListViewColumns.STATUS:
      return <div className={`${styles.statusBox} ${getStatusClass(cell)}`}>{formatStatus(cell)}</div>
    case EListViewColumns.LAST_UPDATED:
      return formatRelativeDate(cell)
    default:
      return cell
  }
}

const ListViewRow: FC<ITableRowProps> = React.memo(({ row, columns }) => {
  return (
    <tr>
      {columns.map((column) => {
        const key = columnKeyMap[column] as keyof IProject
        return <td key={column}>{renderCellContent(column, row[key])}</td>
      })}
    </tr>
  )
})

export { ListViewRow }

import { SilentRequest, IPublicClientApplication } from '@azure/msal-browser'

export const acquireToken = async (pca: IPublicClientApplication, scopes: string[]) => {
  try {
    const activeAccount = pca.getActiveAccount()
    if (!activeAccount) {
      throw new Error('No active account found. User may need to sign in.')
    }

    const request: SilentRequest = {
      account: activeAccount,
      scopes,
    }

    const response = await pca.acquireTokenSilent(request)
    return response.accessToken
  } catch (error) {
    console.error('Token acquisition failed:', error)
    throw error
  }
}

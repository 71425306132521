import { DownOutlined } from '@ant-design/icons'
import { Input, Select } from 'antd'
import { FC, useState, useEffect } from 'react'

import { strings } from '../../../models/consts/strings'
import { ValueType, optionsType } from '../../../models/unions/modal-input.union'
import styles from '../ProjectModal.module.scss'
import '../ProjectModal.scss'
import UserOption from './UserOption/UserOption'

const { Option } = Select

interface ProjectModalInputProps {
  label: string
  placeholder: string
  value: ValueType
  onChange: (value: any) => void
  options?: optionsType[]
  isMultiple?: boolean
  isDisabled?: boolean
  onFocus?: () => void
  connectedUserId: string
}

const ProjectModalInput: FC<ProjectModalInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  options,
  isMultiple = false,
  isDisabled = false,
  onFocus,
  connectedUserId,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<optionsType[]>([])

  useEffect(() => {
    if (options) {
      setFilteredOptions(options.filter((option) => option?.id !== connectedUserId))
    }
  }, [options, connectedUserId])

  const handleSearch = (searchTerm: string) => {
    if (options) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase()

      const filtered = options
        .filter((option) => option?.id !== connectedUserId)
        .filter((option) =>
          option && 'firstName' in option && 'lastName' in option
            ? `${option.firstName} ${option.lastName}`.toLowerCase().includes(lowerCaseSearchTerm)
            : option?.name.toLowerCase().includes(lowerCaseSearchTerm),
        )

      setFilteredOptions(filtered)
    }
  }
  const isMembersInput = label === strings.PROJECT_MODAL.LABELS.PROJECTS_MEMBERS

  return (
    <div className={styles.fieldContainer}>
      <label className={styles.modalLabelText}>{label}</label>
      {options ? (
        <Select
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="project-dropdown-modal"
          suffixIcon={<DownOutlined />}
          mode={isMultiple ? 'multiple' : undefined}
          disabled={isDisabled}
          onFocus={onFocus}
          showSearch
          onSearch={handleSearch}
          filterOption={false}
        >
          {isMembersInput && (
            <Option key={connectedUserId} value={connectedUserId} disabled>
              <UserOption firstName="You" lastName="" />
            </Option>
          )}
          {filteredOptions?.map((option) => {
            const isMembersInput = option && 'firstName' in option && 'lastName' in option
            if (option && isMembersInput) {
              return (
                <Option key={option.id} value={option.id}>
                  <UserOption firstName={option.firstName} lastName={option.lastName} />
                </Option>
              )
            } else if (option && 'name' in option) {
              return (
                <Option key={option.id} value={option.name}>
                  {option.name}
                </Option>
              )
            }
            return null
          })}
        </Select>
      ) : (
        <Input
          className={styles.projectsModalInput}
          value={value as string}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          maxLength={35}
        />
      )}
    </div>
  )
}

export { ProjectModalInput }

enum EListViewColumns {
  CLIENT_NAME = 'Client name',
  NAME = 'Messaging project name',
  STATUS = 'Status',
  LAST_UPDATED = 'Last updated',
}

const columns: EListViewColumns[] = [
  EListViewColumns.CLIENT_NAME,
  EListViewColumns.NAME,
  EListViewColumns.STATUS,
  EListViewColumns.LAST_UPDATED,
]

const columnKeyMap: { [key: string]: string } = {
  'Client name': 'clientName',
  'Messaging project name': 'name',
  Status: 'status',
  'Last updated': 'lastUpdated',
}

export { columns, columnKeyMap, EListViewColumns }

import classNames from 'classnames'
import { FC, useState } from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from '../../components/Sidebar/Sidebar'
import styles from './Layout.module.scss'

const Layout: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleSidebar = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <div
      className={classNames(styles.layoutWrapper, {
        [styles.expanded]: isExpanded,
        [styles.collapsed]: !isExpanded,
      })}
    >
      <Sidebar isExpanded={isExpanded} toggleSidebar={toggleSidebar} />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout

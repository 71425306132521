import { FunctionComponent, useEffect } from 'react'

import { fetchNews } from '../../../store/slices/news.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { Loader } from '../../Loader/Loader'
import { OfflineMsg } from '../../OfflineMsg/OfflineMsg'
import { RefreshButton } from '../../RefreshButton/RefreshButton'
import { NewsListItem } from '../NewsListItem/NewsListItem'
import styles from './NewsScroller.module.scss'

interface NewsScrollerProps {
  title: string
  clientNames: string[]
}

const NewsScroller: FunctionComponent<NewsScrollerProps> = ({ title, clientNames }) => {
  const dispatch = useAppDispatch()
  const { news, isLoading, error } = useAppSelector((state) => state.news)

  useEffect(() => {
    dispatch(fetchNews({ newsCompanies: clientNames }))
  }, [dispatch, clientNames])

  const handleRefresh = () => {
    dispatch(fetchNews({ newsCompanies: clientNames }))
  }

  const hasNews = news.length > 0
  const shouldShowLoader = isLoading
  const shouldShowOfflineMessage = !isLoading && error
  const shouldShowNewsList = !isLoading && !error && hasNews

  return (
    <div className={styles.newsScroller}>
      <div className={styles.newsTopContainer}>
        <h2 className={styles.newsScrollerTitle}>{title}</h2>
        <RefreshButton onClick={handleRefresh} />
      </div>
      <div className={styles.newsBox}>
        {shouldShowLoader && <Loader />}
        {shouldShowOfflineMessage && <OfflineMsg />}
        {shouldShowNewsList && <NewsListItem news={news} />}
      </div>
    </div>
  )
}

export { NewsScroller }

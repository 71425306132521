import { FC, ReactNode, useState } from 'react'

import { PlusIcon } from '../../assets/icons'
import { strings } from '../../models/consts/strings'
import { IProject } from '../../models/interfaces/project.interface'
import { fetchProjects } from '../../store/slices/projects.slice'
import { useAppDispatch } from '../../store/store'
import { Button } from '../Button/Button'
import { EmptyDataMsg } from '../EmptyDataMsg/EmptyDataMsg'
import { Loader } from '../Loader/Loader'
import { ProjectModal } from '../ProjectModal/ProjectModal'
import styles from './ListViewContainer.module.scss'

interface IListViewContainerProps {
  title: string
  data: IProject[]
  userId: string
  isLoading: boolean
  error: string | null
  children: ReactNode
  emptyMsg: string
}

const ListViewContainer: FC<IListViewContainerProps> = ({
  title,
  data,
  userId,
  isLoading,
  error,
  children,
  emptyMsg,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const showModal = () => {
    setOpenModal(true)
  }

  const handleModalOk = () => {
    if (userId) {
      dispatch(
        fetchProjects({
          userId,
        }),
      )
    }
    setOpenModal(false)
  }

  const handleModalCancel = () => {
    setOpenModal(false)
  }

  const hasData = data.length > 0
  const shouldShowLoader = isLoading
  const shouldShowEmptyMessage = !isLoading && (error || data.length === 0)

  return (
    <div className={styles.dataListContainer}>
      <div className={styles.listTopContainer}>
        <h2 className={styles.dataListTitle}>{title}</h2>
        {hasData && <Button Icon={PlusIcon} title={strings.DATA_LIST.NEW_BUTTON} onClick={showModal} />}
      </div>
      {shouldShowLoader && <Loader />}
      {shouldShowEmptyMessage && <EmptyDataMsg msg={emptyMsg} onNewProjectClick={showModal} />}
      {!shouldShowLoader && !shouldShowEmptyMessage && children}
      <ProjectModal open={openModal} onOk={handleModalOk} onCancel={handleModalCancel} />
    </div>
  )
}

export { ListViewContainer }

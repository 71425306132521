import { AvatarIcon, ClientsIcon, CommandCenterIcon, LogoutIcon, MoveoLogo } from '../../assets/icons/index'
import { EAppRoutes } from '../../models/enums/app-routes.enum'

export const sidebarRoutes = [
  {
    path: EAppRoutes.COMMAND_CENTER,
    icon: CommandCenterIcon,
    altText: 'Command Center',
    class: 'commandCenter',
    isStatic: false,
    isButton: false,
  },
  {
    path: EAppRoutes.CLIENTS,
    icon: ClientsIcon,
    altText: 'Clients',
    class: 'clients',
    isStatic: false,
    isButton: false,
  },
  {
    path: EAppRoutes.AVATAR,
    icon: AvatarIcon,
    altText: 'Avatar',
    class: 'avatar',
    isStatic: false,
    isButton: false,
  },
  {
    path: '',
    icon: LogoutIcon,
    altText: 'Logout',
    class: 'logout',
    isButton: true,
    isStatic: false,
  },
  {
    path: '',
    icon: MoveoLogo,
    altText: 'Powered by MOVEO',
    class: 'moveoLogo',
    isStatic: true,
    isButton: false,
  },
]

import { useMsal } from '@azure/msal-react'
import classNames from 'classnames'
import { FC, useState } from 'react'

import { StandoutLogo } from '../../assets/icons'
import { ActionButton } from '../ActionButton/ActionButton'
import styles from './Sidebar.module.scss'
import SidebarLink from './SidebarLink/SidebarLink'
import { sidebarRoutes } from './sidebar.utils'

interface SidebarProps {
  isExpanded: boolean
  toggleSidebar: () => void
}

const Sidebar: FC<SidebarProps> = ({ isExpanded, toggleSidebar }) => {
  const { instance } = useMsal()
  const [selectedTab, setSelectedTab] = useState<string>('/command-center')

  const handleTabSelect = (tabPath: string) => {
    setSelectedTab(tabPath)
  }

  const handleLogout = () => {
    instance.logoutRedirect()
  }

  const topLinks = sidebarRoutes.slice(0, 2)
  const bottomLinks = sidebarRoutes.slice(2)

  return (
    <aside
      className={classNames(styles.sidebar, {
        [styles.expanded]: isExpanded,
        [styles.collapsed]: !isExpanded,
      })}
    >
      <div className={styles.sidebarWrapper}>
        <div className={styles.logo}>
          <StandoutLogo
            className={classNames(styles.logoImg, {
              [styles.expanded]: isExpanded,
            })}
          />
          <hr className={styles.hrBorder} />
        </div>
        <nav className={styles.nav}>
          <div className={styles.topLinksContainer}>
            {topLinks.map((route) => (
              <SidebarLink
                key={route.path}
                path={route.path}
                Icon={route.icon}
                altText={route.altText}
                className={styles[route.class]}
                isExpanded={isExpanded}
                selectedTab={selectedTab}
                onTabSelect={handleTabSelect}
                isStatic={route.isStatic}
              />
            ))}
          </div>
          <div className={styles.bottomLinksContainer}>
            {bottomLinks.map((route) => (
              <SidebarLink
                key={route.path}
                path={route.path}
                Icon={route.icon}
                altText={route.altText}
                className={styles[route.class]}
                isExpanded={isExpanded}
                selectedTab={selectedTab}
                onTabSelect={handleTabSelect}
                isButton={route.isButton}
                onButtonClick={route.isButton ? handleLogout : undefined}
                isStatic={route.isStatic}
              />
            ))}
          </div>
        </nav>
        <ActionButton
          onClick={toggleSidebar}
          isExpanded={isExpanded}
          ariaLabel={isExpanded ? 'Close Sidebar' : 'Open Sidebar'}
          className={styles.actionButtonOpen}
        />
      </div>
    </aside>
  )
}

export default Sidebar

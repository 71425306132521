import { FC, useEffect, useMemo, useState } from 'react'

import { ListView } from '../../components/ListView/ListView'
import { ListViewContainer } from '../../components/ListViewContainer/ListViewContainer'
import { NewsScroller } from '../../components/News/NewsScroller/NewsScroller'
import { Pagination } from '../../components/Pagination/Pagination'
import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { strings } from '../../models/consts/strings'
import { columns } from '../../models/enums/list-columns.enum'
import { fetchProjects } from '../../store/slices/projects.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import styles from './CommandCenter.module.scss'

const CommandCenter: FC = () => {
  const dispatch = useAppDispatch()
  const { projects, isLoading, error, totalItems } = useAppSelector((state) => state.projects)
  const [currentPage, setCurrentPage] = useState<number>(PAGINATION_CONFIG.DEFAULT_PAGE)
  const userId = useAppSelector((state) => state.user.userId)

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchProjects({
          userId,
        }),
      )
    }
  }, [dispatch, userId])

  const handlePageChange = (newPage: number) => {
    if (userId) {
      setCurrentPage(newPage)
      dispatch(
        fetchProjects({
          userId,
          page: newPage,
        }),
      )
    }
  }
  const clientNames = useMemo(() => {
    return Array.from(new Set(projects.map((project) => project.clientName)))
  }, [projects])

  return (
    <main className={styles.commandCenterContainer}>
      <h1 className={styles.title}>Command Center</h1>
      <div className={styles.wrapper}>
        <ListViewContainer
          data={projects}
          title={strings.COMMAND_CENTER_TITLES.DATA_LIST}
          userId={userId}
          isLoading={isLoading}
          error={error}
          emptyMsg={strings.EMPTY_CASES.EMPTY_PROJECTS}
        >
          <ListView columns={columns} data={projects} />
          <Pagination
            totalItems={totalItems}
            itemsPerPage={PAGINATION_CONFIG.DEFAULT_LIMIT}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </ListViewContainer>

        <NewsScroller clientNames={clientNames} title={strings.COMMAND_CENTER_TITLES.NEWS_SCROLLER} />
      </div>
    </main>
  )
}
export { CommandCenter }

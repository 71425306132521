import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { INews } from '../../models/interfaces/news.interface'
import { fetchCompaniesNews } from '../../services/command-center-service'

interface NewsState {
  news: INews[]
  isLoading: boolean
  error: string | null
}

const initialState: NewsState = {
  news: [],
  isLoading: false,
  error: null,
}

export const fetchNews = createAsyncThunk<INews[], { newsCompanies: string[] }>(
  'news/fetchNews',
  async ({ newsCompanies }) => {
    const queryParams = newsCompanies
      .map((company, index) =>
        index === 0 ? `searchKeys=${encodeURIComponent(company)}` : encodeURIComponent(company),
      )
      .join('&searchKeys=')
    const news = await fetchCompaniesNews(queryParams)
    return news
  },
)

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    clearNews: (state) => {
      state.news = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchNews.fulfilled, (state, action: PayloadAction<INews[]>) => {
        state.isLoading = false
        state.news = action.payload
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'Failed to fetch news'
      })
  },
})

export const { clearNews } = newsSlice.actions

export default newsSlice.reducer

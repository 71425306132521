import { IBusinessUnit } from '../../models/interfaces/business-unit.interface'
import { IClient } from '../../models/interfaces/client.interface'
import { IUser } from '../../models/interfaces/users.interface'
import { fetchClients, fetchBusinessUnits, fetchAllUsers } from '../../services/command-center-service'

export const handleFetchClients = async (setClients: (clients: IClient[]) => void) => {
  const response = await fetchClients()
  setClients(response.data)
}

export const handleFetchBusinessUnits = async (
  clientId: string,
  setBusinessUnits: (businessUnits: IBusinessUnit[]) => void,
) => {
  const response = await fetchBusinessUnits(clientId)
  setBusinessUnits(response.data)
}

export const handleFetchUsers = async (setMembers: (users: IUser[]) => void) => {
  const users = await fetchAllUsers()
  setMembers(users.data)
}

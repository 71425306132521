import { Navigate, Route, Routes } from 'react-router-dom'

import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { loginRequest } from '../config/auth-config'
import { EAppRoutes } from '../models/enums/app-routes.enum'
import { Clients } from './clients/Clients'
import { CommandCenter } from './command-center/CommandCenter'
import Layout from './layout/Layout'

export const AppRouter = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
      <Routes>
        {/* Protected Routes Wrapped with Layout */}
        <Route path="/" element={<Layout />}>
          {/* Redirect root to /command-center */}
          <Route index element={<Navigate to={EAppRoutes.COMMAND_CENTER} replace />} />
          <Route path={EAppRoutes.COMMAND_CENTER} element={<CommandCenter />} />
          <Route path={EAppRoutes.CLIENTS} element={<Clients />} />
          {/* Wildcard Route */}
          <Route path={EAppRoutes.WILD_CARD_PATH} element={<Navigate to={EAppRoutes.COMMAND_CENTER} replace />} />
        </Route>
      </Routes>
    </MsalAuthenticationTemplate>
  )
}

import classNames from 'classnames'
import { FC } from 'react'

import { LeftArrow, RightArrow } from '../../assets/icons'
import styles from './Pagination.module.scss'

export interface IPaginationProps {
  totalItems: number
  itemsPerPage: number
  currentPage: number
  onPageChange: (currentPage: number) => void
}

const Pagination: FC<IPaginationProps> = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      onPageChange(newPage)
    }
  }
  return (
    <div className={styles.paginationContainer}>
      <LeftArrow
        className={classNames(styles.arrow, {
          [styles.disabled]: currentPage === 1,
        })}
        onClick={() => handlePageChange(currentPage - 1)}
      />
      <p className={styles.paginationContent}>
        {currentPage} of {totalPages}
      </p>
      <RightArrow
        className={classNames(styles.arrow, {
          [styles.disabled]: currentPage === totalPages,
        })}
        onClick={() => handlePageChange(currentPage + 1)}
      />
    </div>
  )
}

export { Pagination }

import { FC } from 'react'

import { RefreshIcon } from '../../assets/icons'
import styles from './RefreshButton.module.scss'

interface RefreshButtonProps {
  onClick: () => void
}

const RefreshButton: FC<RefreshButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={`btn ${styles.refreshButton}`}>
      <RefreshIcon className={styles.buttonIcon} />
    </button>
  )
}

export { RefreshButton }

import { FC } from 'react'

import { OfflineIcon } from '../../assets/icons'
import { strings } from '../../models/consts/strings'
import styles from './OfflineMsg.module.scss'

const OfflineMsg: FC = () => {
  return (
    <div className={styles.emptyData}>
      <OfflineIcon />
      <p className={styles.msg}>{strings.EMPTY_CASES.OFFLINE}</p>
    </div>
  )
}

export { OfflineMsg }

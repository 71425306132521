import { Button } from 'antd'
import { FC, useState } from 'react'

import { ProjectModalGif } from '../../assets/icons'
import { strings } from '../../models/consts/strings'
import { IBusinessUnit } from '../../models/interfaces/business-unit.interface'
import { IClient } from '../../models/interfaces/client.interface'
import { IUser } from '../../models/interfaces/users.interface'
import { handleFetchBusinessUnits, handleFetchClients, handleFetchUsers } from '../ProjectModal/project-modal.utils'
import { createProject } from '../../store/slices/projects.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { CustomModal } from '../CustomModal/CustomModal'
import styles from './ProjectModal.module.scss'
import './ProjectModal.scss'
import ProjectModalError from './ProjectModalError/ProjectModalError'
import { ProjectModalInput } from './ProjectModalInput/ProjectModalInput'

interface ProjectModalProps {
  open: boolean
  onOk: (data: {
    projectName: string
    selectedClient: string
    selectedBusinessUnit: string
    selectedUsers: string[]
  }) => void
  onCancel: () => void
}

const ProjectModal: FC<ProjectModalProps> = ({ open, onOk, onCancel }) => {
  const dispatch = useAppDispatch()
  const { userId } = useAppSelector((state) => state.user)
  const [projectName, setProjectName] = useState<string>('')
  const [selectedClient, setSelectedClient] = useState<string>()
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([userId])
  const [clients, setClients] = useState<IClient[]>([])
  const [businessUnits, setBusinessUnits] = useState<IBusinessUnit[]>([])
  const [users, setUsers] = useState<IUser[]>([])

  const resetFields = () => {
    setProjectName('')
    setSelectedClient(undefined)
    setSelectedBusinessUnit(undefined)
    setSelectedUsers([userId])
  }

  const handleOk = async () => {
    if (!projectName || !selectedBusinessUnit || !selectedClient || !selectedUsers || !userId) {
      return
    }
    setIsLoading(true)

    try {
      await dispatch(
        createProject({
          name: projectName,
          businessUnitId: selectedBusinessUnit,
          userIds: selectedUsers,
        }),
      ).unwrap()

      setTimeout(() => resetFields(), 1800)
    } catch (error) {
      setIsError(true)
      console.error(strings.PROJECT_MODAL.ERRORS.PROJECT_ERROR, error)
    } finally {
      setTimeout(() => {
        onOk({
          projectName,
          selectedClient: selectedClient || '',
          selectedBusinessUnit: selectedBusinessUnit || '',
          selectedUsers: selectedUsers,
        })
        setIsLoading(false)
      }, 1800)
    }
  }

  const inputData = [
    {
      label: strings.PROJECT_MODAL.LABELS.PROJECT_NAME,
      placeholder: strings.PROJECT_MODAL.LABELS.PROJECT_NAME,
      value: projectName,
      onChange: setProjectName,
    },
    {
      label: strings.PROJECT_MODAL.LABELS.CLIENT_NAME,
      placeholder: strings.PROJECT_MODAL.LABELS.CLIENT_NAME,
      value: selectedClient,
      onChange: (name: string) => {
        const client = clients.find((client) => client.name === name)
        if (client) {
          setSelectedClient(client.name)
          setSelectedBusinessUnit(undefined)
        }
      },
      options: clients,
      onFocus: () => handleFetchClients(setClients),
    },
    {
      label: strings.PROJECT_MODAL.LABELS.BUSINESS_UNIT,
      placeholder: strings.PROJECT_MODAL.LABELS.BUSINESS_UNIT,
      value: selectedBusinessUnit ? businessUnits.find((unit) => unit.id === selectedBusinessUnit)?.name : undefined,
      onChange: (name: string) => {
        const businessUnit = businessUnits.find((unit) => unit.name === name)
        if (businessUnit) {
          setSelectedBusinessUnit(businessUnit.id)
        }
      },
      options: businessUnits,
      isDisabled: !selectedClient,
      onFocus: () => {
        const client = clients.find((client) => client.name === selectedClient)
        if (client) {
          handleFetchBusinessUnits(client.id, setBusinessUnits)
        }
      },
    },

    {
      label: strings.PROJECT_MODAL.LABELS.PROJECTS_MEMBERS,
      placeholder: strings.PROJECT_MODAL.LABELS.PROJECTS_MEMBERS,
      value: selectedUsers,
      onChange: setSelectedUsers,
      options: users,
      isMultiple: true,
      onFocus: () => handleFetchUsers(setUsers),
      connectedUserId: userId,
    },
  ]

  return (
    <CustomModal open={open} onCancel={onCancel} className="project">
      {isError ? (
        <ProjectModalError onClose={() => setIsError(false)} />
      ) : (
        <>
          <div>
            <h1 className={styles.projectModalTitle}>{strings.PROJECT_MODAL.TITLE}</h1>
          </div>
          <div className={styles.modalFields}>
            {inputData.map((data, index) => (
              <ProjectModalInput
                key={index}
                label={data.label}
                placeholder={data.placeholder}
                value={data.value}
                onChange={data.onChange}
                options={data.options}
                isMultiple={data.isMultiple}
                isDisabled={data.isDisabled}
                onFocus={data.onFocus}
                connectedUserId={userId}
              />
            ))}
          </div>
          <div className={styles.projectModalFooter}>
            {isLoading ? (
              <img className={styles.gifAnimation} src={ProjectModalGif} alt={strings.GENERAL_LABELS.SAVING} />
            ) : (
              <Button
                type="primary"
                className={styles.projectModalSaveButton}
                onClick={handleOk}
                disabled={!projectName || !selectedClient || !selectedBusinessUnit || selectedUsers.length === 0}
              >
                {strings.PROJECT_MODAL.FORM_COMPLETE_BUTTON}
              </Button>
            )}
          </div>
        </>
      )}
    </CustomModal>
  )
}

export { ProjectModal }

import { useNavigate } from 'react-router-dom'
import './App.css'
import './assets/styles/_index.scss'
import { AppRouter } from './pages/AppRouter'
import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { CustomNavigationClient } from './utils/navigation-client'

interface AppProps {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  return (
    <MsalProvider instance={pca}>
      <AppRouter />
    </MsalProvider>
  )
}

export default App

import { FC } from 'react'

import { EListViewColumns } from '../../models/enums/list-columns.enum'
import { IProject } from '../../models/interfaces/project.interface'
import styles from './ListView.module.scss'
import { ListViewRow } from './ListViewRow/ListViewRow'

export interface IListProps {
  columns: EListViewColumns[]
  data: IProject[]
}

const ListView: FC<IListProps> = ({ columns, data }) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.tableHeadline}>
          {columns.map((column) => (
            <th key={column}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <ListViewRow key={row.id} row={row} columns={columns} />
        ))}
      </tbody>
    </table>
  )
}

export { ListView }

import { FC } from 'react'

import styles from './Button.module.scss'

export interface IButtonProps {
  title: string
  onClick: () => void
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>
}

const Button: FC<IButtonProps> = ({ title, onClick, Icon }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {Icon && <Icon />}
      <h3 className={styles.buttonTitle}>{title}</h3>
    </button>
  )
}

export { Button }

const baseServerUrl = process.env.REACT_APP_SERVER_API_URL

export const apiEndpoints = {
  PROJECTS: {
    GET_USER_PROJECTS: (userId: string, page: number = 1, limit: number = 2) =>
      `${baseServerUrl}/projects?userId=${userId}&page=${page}&limit=${limit}`,
    CREATE_PROJECT: `${baseServerUrl}/projects`,
  },
  NEWS: {
    GET_COMPANIES_NEWS: (queryParams: string) => `${baseServerUrl}/news?${queryParams}`,
  },
  USERS: {
    GET_ALL_USERS: `${baseServerUrl}/users`,
    LOGIN: `${baseServerUrl}/users/login`,
  },
  BUSINESS_UNITS: {
    GET_BUSINESS_UNIT: (clientId: string) => `${baseServerUrl}/business-units?clientId=${clientId}`,
    CREATE_BUSINESS_UNIT: `${baseServerUrl}/business-units`,
  },
  CLIENTS: {
    GET_ALL_CLIENTS: `${baseServerUrl}/clients`,
  },
}

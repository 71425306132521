import { Avatar } from 'antd'
import { FC } from 'react'

import styles from './UserOption.module.scss'

interface UserOptionProps {
  firstName: string
  lastName: string
}

const UserOption: FC<UserOptionProps> = ({ firstName, lastName }) => {
  const firstInitial = firstName?.[0] || ''
  const lastInitial = lastName?.[0] || ''

  return (
    <div className={styles.userOptionContainer}>
      <Avatar className={styles.avatarUser}>
        {firstInitial}
        {lastInitial}
      </Avatar>
      <span className={styles.avatarUserName}>
        {firstName} {lastName}
      </span>
    </div>
  )
}

export default UserOption

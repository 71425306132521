import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import userSlice from './slices/user.slice'
import projectsReducer from './slices/projects.slice'
import newsReducer from './slices/news.slice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    projects: projectsReducer,
    news: newsReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use this instead of plain useSelector / useDispatch for store types.
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

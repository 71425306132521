import { FunctionComponent } from 'react'

import { INews } from '../../../models/interfaces/news.interface'
import { ActionButton } from '../../ActionButton/ActionButton'
import { formatDate } from '../../../utils/format-date.util'
import styles from './NewsListItem.module.scss'
import { strings } from '../../../models/consts/strings'

export interface INewsProps {
  news: INews[]
}

const NewsListItem: FunctionComponent<INewsProps> = ({ news }) => {
  const handleRedirect = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className={styles.newsContainer}>
      {news.map((item) => (
        <div key={item.title} className={styles.newsItemBackground}>
          <div className={styles.newsItem}>
            <ActionButton
              className={styles.openDetailIcon}
              onClick={() => handleRedirect(item.url)}
              ariaLabel={strings.GENERAL_LABELS.ACTION_BUTTON + item.title}
            />
            <div className={styles.newsTextContainer}>
              <h3 className={styles.itemName}>{item.name}</h3>
              <p className={styles.itemContent}>{item.title}</p>
            </div>
            <div className={styles.sourceDateContainer}>
              <p className={styles.itemSource}>{item.source.name}</p>
              <p className={styles.itemDate}>{formatDate(item.publishedAt)}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export { NewsListItem }

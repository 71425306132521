import { formatDistanceToNow, parseISO } from 'date-fns'

const formatRelativeDate = (isoDate: string | undefined): string => {
  if (!isoDate) {
    console.warn('Missing date:', isoDate)
    return 'Invalid Date'
  }

  try {
    return formatDistanceToNow(parseISO(isoDate), { addSuffix: true })
  } catch (error) {
    console.error('Error parsing date:', isoDate, error)
    return 'Invalid Date'
  }
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export { formatDate, formatRelativeDate }

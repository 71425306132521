import { EStatusEnum } from '../../models/enums/status.enum'
import styles from './ListView.module.scss'

const getStatusClass = (status: EStatusEnum): string => {
  switch (status) {
    case EStatusEnum.PENDING:
      return styles.pending
    case EStatusEnum.COMPLETED:
      return styles.completed
    case EStatusEnum.IN_PROGRESS:
      return styles.inProgress
    case EStatusEnum.DRAFTS:
      return styles.draft
    default:
      return ''
  }
}

const formatStatus = (status: string) =>
  status
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase())

export { formatStatus, getStatusClass }

import { FC } from 'react'

import { ArrowBack, ErrorIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import styles from './ProjectModalError.module.scss'

interface ProjectModalErrorProps {
  onClose: () => void
}
const ProjectModalError: FC<ProjectModalErrorProps> = ({ onClose }) => {
  return (
    <div className={styles.errorModalContainer}>
      <ErrorIcon className={styles.failureAnimation} />
      <h1 className={styles.errorFirstTitle}>{strings.PROJECT_MODAL.ERRORS.ERROR_MSG}</h1>
      <h2 className={styles.errorSecondTitle}>{strings.PROJECT_MODAL.ERRORS.ERROR_MSG2}</h2>
      <button className={styles.backButton} onClick={onClose}>
        <ArrowBack />
        {strings.PROJECT_MODAL.ERRORS.ERROR_BACK_BUTTON}
      </button>
    </div>
  )
}

export default ProjectModalError

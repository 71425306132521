import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '../../config/auth-config'

const msalInstance = new PublicClientApplication(msalConfig)

const ensureMsalInitialized = () => {
  if (!msalInstance.getActiveAccount()) {
    throw new Error('No active account found. User may need to sign in.')
  }
}

export { ensureMsalInitialized, msalInstance }
